<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Fee Offer Report
            <v-spacer></v-spacer>
            <v-download-column-select
              columnHeader=""
              v-if="form.items.data.length > 0"
              :downloadColumns="[]"
              @onDownload="downloadRecord"
            >
              Fee Offer Report
            </v-download-column-select>
          </v-card-title>

          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm3>
                <v-select
                  :disabled="gradeLoading"
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3>
                <v-select
                  :disabled="!grade"
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3>
                <v-select
                  :disabled="!grade"
                  :items="feeHeads"
                  multiple
                  class="pa-0"
                  label="Fee Head"
                  v-model="feeHead"
                  outlined
                  dense
                >
                  <v-list-item slot="prepend-item" ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        :color="feeHead.length > 0 ? 'indigo darken-4' : ''"
                        >{{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-title>All</v-list-item-title>
                  </v-list-item>
                  <v-divider slot="prepend-item" class="mt-2"></v-divider>
                  <v-divider slot="append-item" class="mb-2"></v-divider>
                </v-select>
              </v-flex>
              <v-flex xs2 sm2>
                <search-button
                  :disabled="!grade"
                  style=""
                  permission="receipt-read"
                  @action="get()"
                >
                  Search
                </search-button>
              </v-flex>
              <v-flex xs1>
                <v-spacer></v-spacer>
                <v-btn
                  @click="moreFilterDialogue = true"
                  color="primary"
                  style="margin-top: -20px; float: right"
                  elevation="2"
                  icon
                  ><v-icon>filter_alt</v-icon></v-btn
                >
              </v-flex>
            </v-card-title>
          </v-card>
          <v-dialog v-model="moreFilterDialogue" persistent max-width="550">
            <v-card>
              <v-card-title class="title pa-3 primary white--text">
                <v-icon class="mr-2 white--text">filter_alt</v-icon>
                More Filters
              </v-card-title>
              <v-container grid-list-md pa-0 relative>
                <v-layout row wrap>
                  <v-card-title class="title pa-3">
                    <v-flex xs12>
                      <v-select
                        :disabled="!grade"
                        :items="[
                          { value: 'both', text: 'Both' },
                          { value: 'discount', text: 'Discount' },
                          { value: 'scholar', text: 'Scholar' },
                        ]"
                        class="pa-0"
                        label="Type"
                        v-model="type"
                        outlined
                        dense
                      />
                    </v-flex>

                    <v-flex xs6 outlined>
                      <v-text-field
                        :disabled="!grade"
                        type="number"
                        class="pa-0"
                        label="From (%)"
                        v-model="fromPer"
                        outlined
                        dense
                      />
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs6 outlined>
                      <v-text-field
                        :disabled="!grade"
                        type="number"
                        class="pa-0"
                        label="To (%)"
                        v-model="toPer"
                        outlined
                        dense
                      />
                    </v-flex>
                  </v-card-title>
                </v-layout>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  outlined
                  small
                  @click="moreFilterDialogue = false"
                  >Close</v-btn
                >
                <v-btn color="success" outlined small @click="get()">
                  Filter Now
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- :headers="headers"
            :items="form.items.data"
            :search="search"
            footer-props.items-per-page-options="rowsPerPageItems"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            class="dTable" -->
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            class="dTable"
          >
            <template v-slot:item="{ index, item }">
              <tr
                style="border: 1px solid black; border-right: 1px solid black"
              >
                <td class="text-xs-left" :rowspan="item.fee_offers.length + 1">
                  {{ item.name }}
                  <p style="color: #bbb">{{ item.enroll_code }}</p>
                </td>
                <td class="text-xs-left" :rowspan="item.fee_offers.length + 1">
                  {{ item.fee_offers.length }}
                  <v-icon
                    small
                    color="success"
                    v-if="item.fee_offers.length > 0"
                    >check_circle
                  </v-icon>
                </td>
                <td
                  class="text-xs-left"
                  :rowspan="item.fee_offers.length + 1"
                  style="border-right: 1px solid black"
                >
                  {{ item.grade }} <sup>"{{ item.section }}"</sup>
                </td>
              </tr>
              <template v-for="(feeOffer, i) in item.fee_offers">
                <tr
                  :key="(i + 100) * (index + 1)"
                  :class="{ child: item.fee_offers.length === i + 1 }"
                >
                  <td class="text-xs-left">{{ feeOffer.fee_head }}</td>
                  <td class="text-xs-left">{{ feeOffer.fee_amount }}</td>
                  <td class="text-xs-left">
                    {{ feeOffer.discount_amount }} ({{
                      feeOffer.discount_percentage
                    }}%)
                  </td>
                  <td class="text-xs-left">
                    {{ feeOffer.scholar_amount }} ({{
                      feeOffer.scholar_percentage
                    }}%)
                  </td>
                </tr>
              </template>
            </template>
            <template v-slot:body.append v-if="form.items.data.length">
              <tr>
                <td :colspan="3" class="text-xs-center">
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>{{ feeHeadAmountSum.numberFormat() }}</strong>
                </td>
                <td>
                  <strong>{{ feeHeadDiscountSum.numberFormat() }}</strong>
                </td>
                <td>
                  <strong>{{ feeScholarshipSum.numberFormat() }}</strong>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
import Mixins from '../../../../library/Mixins';

export default {
  mixins: [Mixins],
  data: () => ({
    gradeLoading: false,
    form: new Form(
      {
        bill_month: '',
        grade_id: '',
        enroll: '',
      },
      '/api/report/fee-offer'
    ),
    search: null,
    // rowsPerPageItems: [5, 10, 25, 50, 75],
    // pagination: {
    //   rowsPerPage: 1000,
    // },
    downloadUrl: '',
    headers: [
      { text: 'Name', align: 'left', value: 'Name', sortable: false },
      { text: 'Fee Offer', align: 'left', value: 'fee_offer', sortable: false },
      { text: 'Grade', align: 'left', value: 'grade', sortable: false },
      { text: 'Fee Head', align: 'left', value: 'title', sortable: false },
      {
        text: 'Fee Head Amount',
        align: 'left',
        value: 'amount',
        sortable: false,
      },
      { text: 'Discount', align: 'left', value: 'discount', sortable: false },
      { text: 'Scholar', align: 'left', value: 'scholar', sortable: false },
    ],
    grades: [],
    grade: '',
    sections: [],
    section: '',
    feeHeads: [],
    feeHead: '',
    type: '',
    fromPer: '',
    toPer: '',
    moreFilterDialogue: false,
    collection: [],
    feeHeadAmountSum: 0,
    feeHeadDiscountSum: 0,
    feeScholarshipSum: 0,
  }),

  computed: {
    ...mapState(['batch']),
    selectAllFeeHead() {
      return this.feeHeads.length === this.feeHead.length;
    },
    icon() {
      if (this.selectAllFeeHead) return 'check_box';
      return 'add_box';
    },
  },

  mounted() {
    this.getGrades();
  },

  watch: {
    grade: function () {
      this.section = '';
      this.getSections();
      this.getFeeHead();
    },

    batch: function (value) {
      this.get();
    },
    pagination: function () {
      this.get();
    },
    feeHead: function () {
      this.$emit('input', this.feeHead);
    },
  },

  methods: {
    // queryString() {
    //   let json = this.pagination;
    //   return (
    //     '?' +
    //     Object.keys(json)
    //       .map(function (key) {
    //         if (![null, undefined].includes(json[key]))
    //           return (
    //             encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    //           );
    //       })
    //       .join('&') +
    //     '&gradeId=' +
    //     this.grade +
    //     '&sectionId=' +
    //     this.section +
    //     '&feeHead=' +
    //     this.feeHead +
    //     '&offerType=' +
    //     this.type +
    //     '&fromPer=' +
    //     this.fromPer +
    //     '&toPer=' +
    //     this.toPer
    //   );
    // },

    get(params) {
      if (this.grade) {

        let extraparams = '&gradeId=' +
        this.grade +
        '&sectionId=' +
        this.section +
        '&feeHead=' +
        this.feeHead +
        '&offerType=' +
        this.type +
        '&fromPer=' +
        this.fromPer +
        '&toPer=' +
        this.toPer

         let query = [null, undefined].includes(params)
        ? this.queryString(extraparams)
        : params;

        let $this = this;
        this.form.get(null, query).then(({ data }) => {
          this.downloadUrl = data.download_url;
          this.feeHeadAmountSum = 0;
          this.feeHeadDiscountSum = 0;
          this.feeScholarshipSum = 0;
          //
          data.data.map(function (item) {
            item.fee_offers.map(function (feeOffer) {
              $this.feeHeadAmountSum += parseFloat(feeOffer.fee_amount);
              $this.feeHeadDiscountSum += parseFloat(feeOffer.discount_amount);
              $this.feeScholarshipSum += parseFloat(feeOffer.scholar_amount);
            });
          });
          this.pagination.totalItems = data.meta.total;
          this.moreFilterDialogue = false;
        });
      }
    },

    downloadRecord({ type }) {
      let token = this.$auth.user().id;
      window.open(`${this.downloadUrl}${type}&token=`+token);
    },

    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true')
        .then(({ data }) => {
          this.grades = [{ value: 'all', text: 'ALL' }];
          let $this = this;

          data.data.map((item) => {
            $this.grades.push({ value: item.id, text: item.name });
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },

    getSections() {
      if (this.grade) {
        this.$rest.get('/api/section?grade=' + this.grade).then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
      }
    },

    getFeeHead() {
      this.$rest.get('/api/fee-head').then(({ data }) => {
        this.feeHeads = data.data.map((item) => {
          return { value: item.id, text: item.title };
        });
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllFeeHead) {
          this.feeHead = '';
        } else {
          this.feeHead = this.feeHeads.map((type) => {
            return type.value;
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.child {
  border-bottom: 1px solid #666 !important;
}

.dTable table.v-datatable.v-table.theme--light {
  width: 99%;
  border: 1px solid black;
  align: centre;
}
</style>
